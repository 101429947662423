import Swiper, { Lazy, Navigation } from 'swiper';

/*
  Homepage sliders
*/

/* featuread articles slider */
const swiperFeatured = new Swiper('.swiper__featured-items', {
  modules: [ Navigation ],
  loop: true,
  slidesPerView: 1,

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


/* popular items slider */
const swiperPopular = new Swiper('.swiper__popular-items', {
  modules: [ Navigation ],
  loop: true,

  slidesPerView: 2,
  slidesPerGroup: 2,
  spaceBetween: 3,
  breakpoints: {
    768: {
      slidesPerView: 3,
      slidesPerGroup: 2
    },
    1024: {
      slidesPerView: 6,
      slidesPerGroup: 3
    }
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


/* testimonials slider */
const swiperTestimonials = new Swiper('.swiper__testimonials', {
  modules: [ Navigation, Lazy ],
  loop: true,
  slidesPerView: 1,
  // Disable preloading of all images
  preloadImages: false,
  // Enable lazy loading
  lazy: true,
  loadPrevNext: true,

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});